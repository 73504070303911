.chatlist-header {
    width: 100%;
    height: 60px;
    /* background-color: #EDEFF2; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    position: relative;
}

.chatlist-header-avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: aqua;
    cursor: pointer;
}

.chatlist-header-title {
    color: #005FFF;
    font-size: 24px;
    font-weight: 800;
}

.chatlist-header-title img{
    width: 114.29px;
    height: 20.21px;
}

.chatlist-header-more {
    cursor: pointer;
    position: relative;
}
.MuiDialog-paperWidthSm {
    border-radius: 12px;
}
.chatlist-header-more img{
    width: 32px;
    height: 32px;
}

.chatlist-header-more img:hover{
    border-radius: 50%;
    background: #ccc;
}
.img-active{
    border-radius: 50%;
    background: #ccc;
}
.myself-menu .MuiMenu-paper{
    left: 149px !important;
    top: 48px !important;
    border-radius: 12px;
    padding: 0px;
}
.myself-menu .MuiList-padding {
    padding-left: 8px;
    padding-right: 8px;
}
.myself-menu .MuiMenuItem-root {
    border-radius: 8px;
    padding-left: 8px;
}